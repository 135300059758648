import React, { Component } from 'react'
import CollectionDesignerBlock from 'components/collectionDesignerBlock'
import Layout from 'components/layout'
import ImageWrap from 'components/ImageWrap'
import CollectionProductsSlideshow from 'components/slideshows/collectionProductsSlideshow'
import FullWidthSlideshow from 'components/slideshows/fullWidthSlideshow'
import RelatedProjectsSlideshow from 'components/slideshows/relatedProjectsSlideshow'
import Title from 'components/title'
import DownloadOverlayGrid from 'components/downloadOverlayGrid'
import LoginOverlay from 'components/loginOverlay'
import { trFunction } from 'utils/functions'
import { graphql } from 'gatsby'
// TODO: click scrolldown

class Collection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      downloadOverlayShow: false,
      productInquiryOverlayShow: false,
      loginOverlayShow: false,
      userLocationApproved: false,
    }
    this.toggleState = this.toggleState.bind(this)
    this.showDownloadOverlay = this.showDownloadOverlay.bind(this)
    this.showLoginOverlay = this.showLoginOverlay.bind(this)
    this.hideLoginOverlay = this.hideLoginOverlay.bind(this)
    this.checkLogin = this.checkLogin.bind(this)
  }

  toggleState(e) {
    const state = e.currentTarget.getAttribute('data-state')
    this.setState((prevState) => {
      return {
        [state]: !prevState[state],
      }
    })
    document.querySelector('#header').classList.toggle('show')
  }

  showDownloadOverlay() {
    this.setState({ downloadOverlayShow: true })
    document.querySelector('#header').classList.toggle('show')
  }

  showLoginOverlay() {
    this.setState({ loginOverlayShow: true })
  }

  hideLoginOverlay() {
    this.setState({ loginOverlayShow: false })
  }

  checkLogin() {
    let loginUser = {}
    if (typeof sessionStorage !== 'undefined') {
      if (sessionStorage.loginUser) {
        loginUser = JSON.parse(sessionStorage.loginUser)
      }
    }
    let pass = false
    const getApproval = sessionStorage.getItem('userLocationApproved')
    if (getApproval && getApproval === 'yes') pass = true
    if (sessionStorage.getItem('loginUser') && loginUser.approved) pass = true
    if (pass) {
      this.showDownloadOverlay()
    } else {
      this.showLoginOverlay()
    }
  }

  render() {
    const locale = this.props.data.site.siteMetadata.locale
    const translations = this.props.data.translations
    const tr = (code) => trFunction(code, translations, locale)
    const thisCollection = this.props.data.thisCollection
    const thisPage = this.props.data.thisPage
    const imageAlignClass = thisCollection.imageMainAlign
      ? `obj-align-${thisCollection.imageMainAlign}`
      : ''
    return (
      <Layout>
        <div id="content">
          <DownloadOverlayGrid
            downloadOverlayShow={this.state.downloadOverlayShow}
            toggleState={this.toggleState}
            products={thisCollection.products}
            sourcePage="collection"
            title={thisCollection.title}
            tr={tr}
          />
          <LoginOverlay
            loginOverlayShow={this.state.loginOverlayShow}
            hideLoginOverlay={this.hideLoginOverlay}
            tr={tr}
          />

          <Title
            subtitle={thisPage.title}
            subtitleLink="/collections/collections"
            title={thisCollection.title}
            transparent={true}
          />

          <section className="header-large-image">
            <div className="dim-overlay" />
            <div
              className={`img-wrapper-proportion no-padding ${imageAlignClass} object-fit-holder`}
            >
              <ImageWrap image={thisCollection.imageMain} />
            </div>
            <a className="ui scroll-down">
              <div className="arrow-fade-attention">
                <div className="arrow-down" />
              </div>
            </a>
            <div className="menu-bottom-trigger" />
          </section>
          <section className="page-content push-header-large-image">
            <CollectionDesignerBlock
              collection={thisCollection}
              toggleState={this.toggleState}
              checkLogin={this.checkLogin}
              tr={tr}
            />
            {thisCollection.products && (
              <CollectionProductsSlideshow products={thisCollection.products} />
            )}
            {thisCollection.slides && (
              <FullWidthSlideshow slides={thisCollection.slides} />
            )}
            {thisCollection.projects && (
              <RelatedProjectsSlideshow
                projects={thisCollection.projects}
                sourcePage="collection"
                tr={tr}
              />
            )}
          </section>
        </div>
      </Layout>
    )
  }
}
export default Collection

export const collectionQuery = graphql`
  query ($slug: String) {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    thisCollection: contentfulCollections(slug: { eq: $slug }) {
      id
      title
      imageMain {
        ...GatsbyImage
      }
      imageMainAlign
      imageThumb {
        ...GatsbyImage
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      designer {
        title
        slug
        image {
          ...GatsbyImage
        }
      }
      products {
        title
        slug
        collection {
          order
        }
        categories {
          order
        }
        datePosted
        imageThumb {
          ...GatsbyImage
        }
        downloads {
          file {
            url
            fileName
            details {
              size
            }
            contentType
          }
          description
          ...GatsbyImage
        }
      }
      projects {
        title
        slug
        isFeatured
        datePosted
        location {
          id
          title
        }
        image {
          ...GatsbyImage
        }
      }
      slides {
        id
        images {
          title
          ...GatsbyImage
        }
        caption
        highlightPoints {
          title
          caption
          triggerPositionLeft
          triggerPositionTop
          popupAlign
        }
      }
    }
    thisPage: contentfulPages(slug: { eq: "collections" }) {
      title
      slug
    }
  }
`
